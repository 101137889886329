<template>
  <component
    :is="component"
    :to="to"
    :disabled="disabled || loading"
    :type="type"
    :aria-pressed="active"
    :target="target"
    class="relative inline-flex min-w-0 max-w-full items-center justify-center rounded-full border-2 border-solid font-medium transition-all duration-150 disabled:border-gray-500 disabled:bg-gray-500 disabled:text-gray-700"
    :class="classes"
    :form="form"
    @click.stop="emit('click', $event)"
  >
    <Transition v-bind="animationProps">
      <span
        v-if="$slots.prefix && !loading"
        class="inline-block h-4 overflow-visible align-baseline leading-none"
        :class="{ [iconClasses]: state === 'loading-after-leave' }"
      >
        <slot name="prefix" />
      </span>
    </Transition>
    <!-- Slot especially for Icones -->
    <Transition v-bind="animationProps">
      <span
        v-if="$slots.icon && !loading"
        class="inline-block overflow-visible text-center align-baseline leading-none"
        :class="{ [iconClasses]: state === 'loading-after-leave' }"
      >
        <span class="inline-block align-sub">
          <UIIcon :size="size">
            <slot name="icon" />
          </UIIcon>
        </span>
      </span>
    </Transition>

    <Transition
      v-bind="animationProps"
      @before-enter="setState('loading-before-enter')"
      @before-leave="setState('loading-before-leave')"
      @enter="setState('loading-enter')"
      @leave="setState('loading-leave')"
      @appear="setState('loading-appear')"
      @after-enter="setState('loading-after-enter')"
      @after-leave="setState('loading-after-leave')"
      @after-appear="setState('loading-after-appear')"
    >
      <span
        v-if="loading"
        class="inline-block h-4 align-baseline leading-none"
        :class="{ [iconClasses]: state === 'loading-after-enter' }"
      >
        <span
          class="inline-block scale-125"
          :class="{ 'scale-125': !$slots.icon, 'scale-150': $slots.icon }"
        >
          <span class="block animate-spin text-white">
            <MaterialSymbolsProgressActivity />
          </span>
        </span>
      </span>
    </Transition>
    <span
      class="min-w-0 leading-none"
      :class="contentClasses"
    >
      <slot />
    </span>
  </component>
</template>

<script lang="ts" setup>
import { computed, ref, useSlots } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { RouterLink } from 'vue-router';

import UIExternalLink from '@/modules/ui/components/UIExternalLink.vue';
import UIIcon from '@/modules/ui/components/UIIcon.vue';
import type { UIButtonSize, UIButtonType, UIButtonVariant } from '@/modules/ui/types/ui-button-types';

type AnimationState =
  | 'loading-after-appear'
  | 'loading-before-leave'
  | 'loading-enter'
  | 'loading-leave'
  | 'loading-appear'
  | 'loading-after-leave'
  | 'loading-before-enter'
  | 'loading-after-enter';

interface Props {
  type?: UIButtonType;
  variant?: UIButtonVariant;
  to?: string | RouteLocationRaw | object;
  disabled?: boolean;
  responsive?: boolean;
  loading?: boolean;
  active?: boolean;
  size?: UIButtonSize;
  external?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
  form?: string;
  wrap?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  variant: 'primary',
  to: undefined,
  disabled: false,
  responsive: false,
  loading: false,
  active: false,
  size: 'md',
  form: undefined,
  target: undefined,
  wrap: false,
});

const slots = useSlots();

const component = computed(() => {
  if (props.to && !props.disabled) {
    if (props.external) {
      return UIExternalLink;
    }

    return RouterLink;
  } else {
    return 'button';
  }
});

const target = computed(() => {
  if (props.external) {
    return props.target || '_blank';
  }

  return undefined;
});

/**
 * Warning: don't replace e.g. `w-[1.5rem]` with `w-6`.
 * These classes are needed to make the transition with Vues Transition-component work.
 * TLDR; Classes with the same name will be removed at the end of the transition.
 */
const iconClasses = computed(() => {
  return slots.icon ? 'mr-2 w-6' : 'mr-2 w-4';
});

const iconClassesAnimation = computed(() => {
  return slots.icon ? 'mr-2 w-[1.5rem]' : 'mr-2 w-[1rem]';
});

const animationProps = computed(() => {
  return {
    'enter-active-class': 'transition-all ease-in-out duration-300 overflow-visible',
    'enter-from-class': 'w-[0] mr-[0] opacity-0',
    'enter-to-class': `${iconClassesAnimation.value}`,
    'leave-active-class': 'transition-all ease-in-out duration-300 overflow-visible',
    'leave-from-class': `${iconClassesAnimation.value}`,
    'leave-to-class': 'w-[0] mr-[0] opacity-0',
  };
});

const state = ref<AnimationState>(props.loading ? 'loading-after-enter' : 'loading-after-leave');

function setState(newState: AnimationState) {
  state.value = newState;
}

const emit = defineEmits<{ (e: 'click', data: Event): void }>();
const classes = computed(() => {
  const classes = [];

  if (props.variant === 'primary') {
    if (props.active) {
      classes.push('bg-text border-text text-white');
    } else {
      classes.push('bg-primary border-primary text-white');
    }
    classes.push('hover:bg-secondary hover:border-secondary hover:text-primary');
    classes.push('active:bg-text active:border-text active:text-white');
  }

  if (props.variant === 'secondary') {
    classes.push('bg-white');

    if (props.active) {
      classes.push('border-text bg-white text-text');
    } else {
      classes.push('border-primary bg-white text-primary');
    }

    classes.push('hover:bg-primary hover:border-primary hover:text-white');
    classes.push('active:border-text active:bg-white active:text-text');
  }

  if (props.variant === 'danger') {
    classes.push('text-danger bg-white border-danger');
    classes.push('hover:bg-danger hover:text-white');
  }

  if (props.variant === 'link') {
    if (props.active) {
      classes.push('bg-transparent border-transparent text-primary border-secondary');
    } else {
      classes.push('bg-transparent border-transparent text-primary');
    }
    classes.push('hover:bg-transparent hover:border-secondary hover:text-primary');
    classes.push('active:bg-secondary active:border-secondary active:text-primary');
  }

  if (props.size === 'sm') {
    classes.push('px-3');
    classes.push('text-sub');
  } else {
    classes.push('px-6');
    classes.push('text-sub sm:text-base');
  }

  if (props.responsive) {
    classes.push('w-full mx-auto');
  }

  if (props.loading) {
    classes.push('cursor-wait');
  }

  if (props.disabled && !props.loading) {
    classes.push('cursor-not-allowed');
  }

  if (!props.wrap) {
    classes.push('truncate');
  }

  return classes;
});

const contentClasses = computed(() => {
  const classes: string[] = [];

  if (props.size === 'sm') {
    classes.push('py-1.5 ');
  } else {
    classes.push('py-3');
  }

  if (!props.wrap) {
    classes.push('truncate');
  }

  return classes;
});
</script>
