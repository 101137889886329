import type { IUnitType, IUnitTypeAvailableResponse, IUnitTypeCategory } from '@/types';
import type { IPaginatedResponse, IPaginationPayload, IResponse } from '@/types/api';
import { formatISODate } from '@/utils/date-utils';
import { httpClient } from '@/utils/fetch-utils';

export function getUnitTypeCategories(locationIds: number[]): Promise<IResponse<IUnitTypeCategory[]>> {
  return httpClient
    .get('/unit-type/category', {
      params: {
        locationIds: locationIds.join(','),
      },
    })
    .then((response) => response.data);
}

export function getUnitTypes(
  locationId: number,
  categoryIds: number[] = [],
  pagination: IPaginationPayload,
): Promise<IPaginatedResponse<IUnitType[]>> {
  const params: Record<string, string | number> = {
    ...pagination,
    locationIds: locationId,
  };

  if (categoryIds && categoryIds.length > 0) {
    params.categoryIds = categoryIds.join(',');
  }

  return httpClient
    .get<IPaginatedResponse<IUnitType[]>>('/unit-type', {
      params,
    })
    .then((response) => response.data);
}

export function getAvailableUnitTypes(
  unitTypeId: number,
  categoryId: number,
  moveInDate: Date | string,
): Promise<IResponse<IUnitTypeAvailableResponse>> {
  const params: Record<string, string | number> = {
    moveIn: formatISODate(moveInDate),
    categoryId,
  };

  return httpClient
    .get<IResponse<IUnitTypeAvailableResponse>>(`/unit-type/${unitTypeId}/available-unit`, {
      params,
    })
    .then((response) => response.data);
}

export function getUnitType(unitTypeId: number | string): Promise<IUnitType> {
  return httpClient.get<IResponse<IUnitType>>(`/unit-type/${unitTypeId}`).then((response) => response.data?.data);
}
