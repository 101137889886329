import type { QueryObserverResult } from '@tanstack/vue-query';
import { defineStore } from 'pinia';
import { computed, type ComputedRef, shallowRef } from 'vue';

import {
  invalidateLoggedInCustomerQuery,
  prefillLoggedInCustomerQuery,
  useLoggedInCustomer,
} from '@/api/queries/use-logged-in-customer-query';
import { getLoggedInCustomerInformation } from '@/api/services/customer-portal-service';
import type { IAuthStateModel } from '@/stores/auth-store';
import type { IAuthCustomer } from '@/types';
import type { IResponse } from '@/types/api';
import type { IAuthStoreChild } from '@/types/auth-store-types';
import { getFullName } from '@/utils/customer-utils';

export interface IUseAuthStoreLoggedInCustomerModel extends IAuthStoreChild {
  customer: ComputedRef<IAuthCustomer | undefined>;
  fullName: ComputedRef<string>;
  isCustomerDataComplete: ComputedRef<boolean>;

  refetch(): Promise<QueryObserverResult<IResponse<IAuthCustomer>, unknown>>;
}

export const useAuthLoggedInCustomerStore = defineStore('auth-logged-in-customer', () => {
  const authState = shallowRef<IAuthStateModel | undefined>();
  const isQueryEnabled = computed(() => authState.value?.authState === 'logged-in');
  const { data, isLoading, refetch } = useLoggedInCustomer(isQueryEnabled);
  const customer = computed(() => data.value?.data);

  return {
    customer: computed(() => (isQueryEnabled.value && !isLoading.value ? customer.value : undefined)),
    async onAfterLogout(): Promise<void> {
      authState.value = undefined;
      invalidateLoggedInCustomerQuery();
    },
    async onAfterLogin(authStore: IAuthStateModel): Promise<void> {
      authState.value = authStore;
      return getLoggedInCustomerInformation().then((customerData) => {
        prefillLoggedInCustomerQuery(customerData);
      });
    },

    refetch,
    fullName: computed(() => (customer.value ? getFullName(customer.value) : '')),
    isCustomerDataComplete: computed(() => {
      const values = [
        customer.value?.firstname || '',
        customer.value?.lastname || '',
        customer.value?.email || '',
        customer.value?.phoneNumber || '',
        customer.value?.phonePrefix || '',
      ];
      return customer.value != null && values.every((v) => v != null && String(v).length > 0);
    }),
  } as IUseAuthStoreLoggedInCustomerModel;
});
