import { createRouter, createWebHistory, type RouteLocationRaw, type RouteRecordRaw } from 'vue-router';

import { authRoutes } from '@/modules/auth/auth-routes';
import { bookingFlowRoutes } from '@/modules/booking-flow/booking-flow-routes';
import { bookingFlowBasketRoutes } from '@/modules/booking-flow-basket/booking-flow-basket-routes';
import { customerPortalRoutes } from '@/modules/customer-portal/customer-portal-routes';
import { setupGuards } from '@/router/guards/guards';
import { type IAppRouteMeta, navigationNames } from '@/router/router-constants';

export type IAppRoutes = Readonly<RouteRecordRaw & { meta?: IAppRouteMeta }>[];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: navigationNames.notFound,
      path: '',
      alias: ['/404'],
      strict: true,
      component: () => import('@/modules/base/pages/NotFound.vue'),
    },
    {
      name: navigationNames.serverError,
      path: '/server-error',
      component: () => import('@/modules/base/pages/GenericServerErrorPage.vue'),
    },
    {
      name: navigationNames.forbidden,
      path: '/forbidden',
      component: () => import('../modules/base/pages/ForbiddenPage.vue'),
    },
    ...authRoutes,
    ...bookingFlowBasketRoutes,
    ...customerPortalRoutes,
    ...bookingFlowRoutes,
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/modules/base/pages/NotFound.vue'),
    },
  ] as IAppRoutes,
});

// Adding guards to the router AFTER the session check guard. The session check needs to be the first guard!
setupGuards(router);

/**
 * Placeholder for NUXTs `navigateTo` method. Used to reduce amount of work after switching from Nuxt to Vue
 * @param to
 */
export function navigateTo(to: RouteLocationRaw) {
  return router.push(to);
}

export default router;
