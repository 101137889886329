import type { IProduct } from '@/modules/booking-flow/types/booking-flow-types';
import { getDefaultPaginationValues, type IPaginatedResponse, type IPaginationPayload } from '@/types/api';
import { httpClient } from '@/utils/fetch-utils';

export interface IGetProductsPayload {
  unitTypeId: number;
  billingPeriodId: number;
  pagination?: IPaginationPayload;
}

export function getOptionalProducts(options: IGetProductsPayload): Promise<IPaginatedResponse<IProduct[]>> {
  return httpClient
    .get<IPaginatedResponse<IProduct[]>>(`/unit-type/${options.unitTypeId}/optional-product`, {
      params: {
        billingPeriodId: options.billingPeriodId,
        ...(options.pagination || getDefaultPaginationValues()),
      },
    })
    .then((response) => response.data);
}
