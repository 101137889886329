import { type QueryKey, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { MaybeRef } from 'vue';
import { computed, ref, unref } from 'vue';

import {
  cancelBooking,
  getAllBookings,
  getAllLocations,
  getBookings,
  getInvoiceHistory,
  getOpenInvoices,
  getPaymentMethods,
  getSetupIntent,
  updatePaymentMethodBookings,
} from '@/api/services/customer-portal-service';
import { initialPageSizes } from '@/config/config';
import type { IPortalCancelBookingPayload, IPortalUpdatePaymentMethodPayload } from '@/types';
import { getDefaultPaginationValues } from '@/types/api';
import { getNextPagePayload, hasNextPage } from '@/utils/pagination-utils';

export const queryOptions = {
  refetchOnWindowFocus: true,
  staleTime: 5000,
  refetchInterval: 60000,
};

const scope = ['customer-portal'];

export function getKey(...args: unknown[]): QueryKey {
  return [...scope, ...args];
}

export function useBookingsInfiniteQuery(locationId: MaybeRef<number | undefined>) {
  const locationIdRef = ref(locationId);
  const enabled = computed(() => unref(locationId) != null);

  return useInfiniteQuery({
    queryKey: getKey(locationId, 'bookings'),
    queryFn(params) {
      return getBookings({
        locationId: locationIdRef.value as number,
        pagination: params.pageParam || getDefaultPaginationValues(initialPageSizes.bookingOptionsBookingPlans),
      });
    },
    initialPageParam: getDefaultPaginationValues(initialPageSizes.bookingOptionsBookingPlans),
    getNextPageParam(lastPage) {
      if (hasNextPage(lastPage.pagination)) {
        return getNextPagePayload(lastPage.pagination);
      }
    },
    enabled,
    ...queryOptions,
  });
}

export function useGetAllBookingsQuery(
  locationId: MaybeRef<number | undefined>,
  pageSize: number = initialPageSizes.bookingOptionsBookingPlans,
) {
  const locationIdRef = ref(locationId);
  const enabled = computed(() => unref(locationId) != null);

  return useQuery({
    queryKey: getKey(locationId, 'all-bookings-not-paginated'),
    queryFn() {
      return getBookings({
        locationId: locationIdRef.value as number,
        pagination: getDefaultPaginationValues(pageSize),
      });
    },
    enabled,
    ...queryOptions,
  });
}

export function useBookingsQuery(locationId: MaybeRef<number | undefined>) {
  const locationIdRef = ref(locationId);
  const enabled = computed(() => unref(locationId) != null);

  return useQuery({
    queryKey: getKey(locationId, 'all-bookings'),
    queryFn() {
      return getAllBookings({
        locationId: locationIdRef.value as number,
      });
    },
    enabled,
    ...queryOptions,
  });
}

export function useInvoiceHistoryPaginatedQuery(locationId: MaybeRef<number | undefined | null>) {
  const locationIdRef = ref(locationId);
  const enabled = computed(() => unref(locationId) != null);

  return useInfiniteQuery({
    queryKey: getKey(locationId, 'invoices', 'history'),
    queryFn: (params) => {
      return getInvoiceHistory({
        locationId: locationIdRef.value as number,
        pagination: params.pageParam || getDefaultPaginationValues(initialPageSizes.customerPortalInvoiceHistory),
      });
    },
    initialPageParam: getDefaultPaginationValues(initialPageSizes.customerPortalInvoiceHistory),
    getNextPageParam(lastPage) {
      if (hasNextPage(lastPage.pagination)) {
        return getNextPagePayload(lastPage.pagination);
      }
    },
    enabled,
    ...queryOptions,
  });
}

export function useOpenInvoicesPaginatedQuery(locationId: MaybeRef<number | undefined | null>) {
  const locationIdRef = ref(locationId);
  const enabled = computed(() => unref(locationId) != null);

  return useInfiniteQuery({
    queryKey: getKey(locationId, 'invoices', 'open'),
    queryFn(params) {
      return getOpenInvoices({
        locationId: locationIdRef.value as number,
        pagination: params.pageParam || getDefaultPaginationValues(initialPageSizes.customerPortalOpenInvoices),
      });
    },
    initialPageParam: getDefaultPaginationValues(initialPageSizes.customerPortalOpenInvoices),
    getNextPageParam(lastPage) {
      if (hasNextPage(lastPage.pagination)) {
        return getNextPagePayload(lastPage.pagination);
      }
    },
    enabled,
    ...queryOptions,
  });
}

export function useCustomerPaymentMethodsQuery(locationId: MaybeRef<number | undefined | null>) {
  const locationIdRef = ref(locationId);
  const enabled = computed(() => unref(locationId) != null);

  return useQuery({
    queryKey: getKey(locationId, 'payment-methods'),
    queryFn: () => getPaymentMethods(locationIdRef.value as number),
    enabled,
    ...queryOptions,
  });
}

export function useCustomerSetupIntentQuery(locationId: MaybeRef<number | undefined | null>) {
  const locationIdRef = ref(locationId);
  const enabled = computed(() => unref(locationId) != null);

  return useQuery({
    queryKey: getKey(locationId, 'setup-intent'),
    queryFn: () => getSetupIntent(locationIdRef.value as number),
    enabled,
    ...queryOptions,
  });
}

export function useBookingUpdatePaymentMethodMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (props: { id: number; payload: IPortalUpdatePaymentMethodPayload }) => {
      return updatePaymentMethodBookings({
        locationId: props.id,
        payload: props.payload,
      });
    },
    async onSuccess(response) {
      await queryClient.invalidateQueries({ queryKey: getKey(response.data.location.id, 'all-bookings') });
      await queryClient.invalidateQueries({ queryKey: getKey(response.data.location.id, 'bookings') });
    },
  });
}

export function useBookingCancelMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (props: { bookingId: number; payload: IPortalCancelBookingPayload }) => {
      return cancelBooking(props.bookingId, props.payload);
    },
    async onSuccess(response) {
      await queryClient.invalidateQueries({ queryKey: getKey(response.data.location.id, 'all-bookings') });
      await queryClient.invalidateQueries({ queryKey: getKey(response.data.location.id, 'bookings') });
    },
  });
}

export function useLocationsQuery() {
  return useQuery({
    queryKey: getKey('locations'),
    queryFn: () => getAllLocations(),
    ...queryOptions,
  });
}
