import { groupBy } from 'lodash-es';
import type { RouteRecordRaw } from 'vue-router';

import { guardNames, navigationNames } from '@/router/router-constants';

export const bookingFlowBasketRouteNames = {
  continueCheckout: 'booking-flow-basket-continue-checkout',
  esignatureWebhook: 'booking-flow-basket-esignature-webhook',
  idCheckWebhook: 'booking-flow-basket-id-check-webhook',
  checkout: 'booking-flow-basket-root',
  payment: 'booking-flow-basket-step-payment',
  paymentVerify: 'booking-flow-basket-step-payment-verify',
  eSignature: 'booking-flow-basket-e-signature',
  eSignatureSigned: 'booking-flow-basket-e-signature-signed',
  eSignatureCancelled: 'booking-flow-basket-e-signature-cancelled',
  eSignatureExpired: 'booking-flow-basket-e-signature-expired',
  idCheck: 'booking-flow-basket-step-id-check',
  idCheckExpired: 'booking-flow-basket-step-id-check-expired',
  idCheckCancelled: 'booking-flow-basket-step-id-check-cancelled',
  idCheckInProgress: 'booking-flow-basket-step-id-check-in-progress',
  createBooking: 'booking-flow-basket-create-booking',
  sessionEnded: 'booking-flow-basket-session-ended',
  success: 'booking-flow-basket-success',
};

const grouped = groupBy(Array.from(Object.values(bookingFlowBasketRouteNames) as string[]), (d) => d);

Object.keys(grouped).forEach((key) => {
  if (grouped[key].length > 1) {
    console.warn('🚨 duplicate in route names found: ', key, `Appearance: ${grouped[key].length}`);
  }
});

const commonRouteMeta = {
  middleware: [guardNames.requiresLanguage],
};

export const bookingFlowBasketRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/modules/booking-flow/pages/BookingPortalLocationIdBasedRootPage.vue'),
    children: [
      // Static pages
      {
        name: bookingFlowBasketRouteNames.eSignatureSigned,
        // Keep the path as it is because it is defined by the BE
        path: '/:locationId(\\d+)/esignature-signed',
        component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepESignatureSignedPage.vue'),
        meta: {
          ...commonRouteMeta,
        },
      },
      // Checkout related pages
      {
        name: bookingFlowBasketRouteNames.success,
        path: '/:locationId(\\d+)/checkout/success',
        component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepSuccessPage.vue'),
        meta: {
          ...commonRouteMeta,
        },
      },
      {
        name: bookingFlowBasketRouteNames.sessionEnded,
        path: '/:locationId(\\d+)/checkout/session-ended',
        component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepSessionEndedPage.vue'),
        meta: {
          ...commonRouteMeta,
        },
      },

      // Solo pages that act on their own. Mostly redirects or webhooks.
      // They do not have auto error handling like the children of BookingFlowBasketStepsRootPage
      {
        name: bookingFlowBasketRouteNames.esignatureWebhook,
        // Keep the path as it is because it is defined by the BE
        path: '/:locationId(\\d+)/checkout/esignature/:basketId',
        component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepESignatureWebhookPage.vue'),
        meta: {
          ...commonRouteMeta,
        },
      },
      {
        name: bookingFlowBasketRouteNames.idCheckWebhook,
        // Keep the path as it is because it is defined by the BE
        path: '/:locationId(\\d+)/checkout/id-check/:basketId',
        component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepIdCheckWebhookPage.vue'),
        meta: {
          ...commonRouteMeta,
        },
      },
      {
        name: bookingFlowBasketRouteNames.paymentVerify,
        path: '/:locationId(\\d+)/checkout/step/payment-verify',
        component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepPaymentVerifyPage.vue'),
        meta: {
          ...commonRouteMeta,
        },
      },

      // Flow pages that directly work with the state and have error handling out of the box
      {
        path: '/:locationId(\\d+)/checkout',
        name: bookingFlowBasketRouteNames.checkout,
        component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepsRootPage.vue'),
        meta: {
          ...commonRouteMeta,
        },
        children: [
          {
            name: bookingFlowBasketRouteNames.payment,
            path: 'step/payment',
            component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepPaymentPage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
          {
            name: bookingFlowBasketRouteNames.eSignature,
            path: 'step/e-signature',
            component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepESignaturePage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
          {
            name: bookingFlowBasketRouteNames.eSignatureCancelled,
            path: 'step/e-signature-cancelled',
            component: () =>
              import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepESignatureCancelledPage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
          {
            name: bookingFlowBasketRouteNames.eSignatureExpired,
            path: 'step/e-signature-expired',
            component: () =>
              import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepESignatureExpiredPage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
          {
            name: bookingFlowBasketRouteNames.idCheck,
            path: 'step/id-check',
            component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepIdCheckPage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
          {
            name: bookingFlowBasketRouteNames.idCheckExpired,
            path: 'step/id-check-expired',
            props: {
              type: 'expired',
            },
            component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepIdCheckErrorPage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
          {
            name: bookingFlowBasketRouteNames.idCheckCancelled,
            path: 'step/id-check-cancelled',
            props: {
              type: 'cancelled',
            },
            component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepIdCheckErrorPage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
          {
            name: bookingFlowBasketRouteNames.idCheckInProgress,
            path: 'step/id-check-in-progress',
            component: () =>
              import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepIdCheckInProgressPage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
          {
            name: bookingFlowBasketRouteNames.createBooking,
            path: 'step/create-booking',
            component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketStepCreateBookingPage.vue'),
            meta: {
              ...commonRouteMeta,
            },
          },
        ],
      },
      // Keep this down here
      {
        name: bookingFlowBasketRouteNames.continueCheckout,
        path: '/:locationId(\\d+)/checkout/:basketId',
        component: () => import('@/modules/booking-flow-basket/pages/BookingFlowBasketContinueCheckoutPage.vue'),
        meta: {
          ...commonRouteMeta,
        },
      },
    ],
  },
];

export const routesFactoryBookingFlowBasket = {
  checkout(options?: { reason?: string }) {
    return {
      name: bookingFlowBasketRouteNames.checkout,
      query: {
        reason: options?.reason,
      },
    };
  },
  payment(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.payment,
      params: {
        locationId: locationId,
      },
    };
  },
  idCheck(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.idCheck,
      params: {
        locationId: locationId,
      },
    };
  },
  idCheckExpired(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.idCheckExpired,
      params: {
        locationId: locationId,
      },
    };
  },
  idCheckCancelled(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.idCheckCancelled,
      params: {
        locationId: locationId,
      },
    };
  },
  idCheckInProgress(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.idCheckInProgress,
      params: {
        locationId: locationId,
      },
    };
  },
  paymentVerify(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.paymentVerify,
      params: {
        locationId: locationId,
      },
    };
  },
  createBooking(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.createBooking,
      params: {
        locationId: locationId,
      },
    };
  },
  eSignatureCancelled(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.eSignatureCancelled,
      params: {
        locationId: locationId,
      },
    };
  },
  eSignature(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.eSignature,
      params: {
        locationId: locationId,
      },
    };
  },
  eSignatureExpired(locationId: number) {
    return {
      name: bookingFlowBasketRouteNames.eSignatureExpired,
      params: {
        locationId: locationId,
      },
    };
  },
  success(props: { name?: string; email?: string }) {
    return {
      name: bookingFlowBasketRouteNames.success,
      query: {
        i: btoa(JSON.stringify(props || {})),
      },
    };
  },
  eSignatureSigned() {
    return {
      name: bookingFlowBasketRouteNames.eSignatureSigned,
    };
  },
  sessionEnded(locationId?: number | string) {
    if (locationId == null) {
      return { name: navigationNames.notFound };
    }

    return {
      name: bookingFlowBasketRouteNames.sessionEnded,
      params: {
        locationId,
      },
    };
  },
  continueCheckout(basketId: string | undefined, locationId?: number | string) {
    return {
      name: bookingFlowBasketRouteNames.continueCheckout,
      params: {
        basketId,
        locationId,
      },
    };
  },
};

export const navigationHints = {
  paymentDone: 'payment-done',
  retry: 'retry',
  wrongStep: 'wrong-step',
};
