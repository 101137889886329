import dayjs from 'dayjs';
import { cloneDeep, isString } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, type ComputedRef, nextTick, reactive } from 'vue';
import { type RouteLocationNamedRaw, useRouter } from 'vue-router';

import { getOptionalProducts } from '@/api/services/product-service.ts';
import { getAvailableUnitTypes, getUnitType, getUnitTypeCategories } from '@/api/services/unit-type-service.ts';
import { useCategoryIdQueryParameter } from '@/composables/use-category-id-query-parameter.ts';
import { useMoveInDate } from '@/composables/use-move-in-date';
import {
  bookingFlowPageOder,
  bookingFlowRouteNames,
  routesFactoryBookingFlow,
} from '@/modules/booking-flow/booking-flow-routes';
import { useBookingFlowStorePersistence } from '@/modules/booking-flow/composables/use-booking-flow-store-persistence';
import type {
  IBookingFlowPredefinedSessionData,
  IBookingOptionSection,
  ICustomerData,
  IProduct,
} from '@/modules/booking-flow/types/booking-flow-types';
import type { IBookingPlan, IInsurance, IUnitType, IUnitTypeAvailableResponse, IUnitTypeCategory } from '@/types';
import type { Maybe } from '@/types/utility-types';
import { getCustomerFormDataAndValidate } from '@/utils/customer-utils';
import { formatISODate } from '@/utils/date-utils';
import { parseToInteger } from '@/utils/format-utils';

export interface IBookingFlowStateModel {
  sessionDataLoading: boolean;
  locationId: number | undefined;
  unitTypeCategory: IUnitTypeCategory | undefined;
  unitType: IUnitType | undefined;
  unitAvailable: boolean | undefined;
  moveInDate: string | undefined;
  bookingPlan: IBookingPlan | undefined;
  insurance: IInsurance | undefined;
  discountCode: string | undefined;
  customerData: Partial<ICustomerData> | undefined;
  selectedProducts: IProduct[];
}

export interface IBookingFlowTemporaryStateModel {
  unitTypeAvailability: IMinimalQueryResult<IUnitTypeAvailableResponse>;
  optionalProductsAvailable: IMinimalQueryResult<boolean>;
}

export type ICustomerFormProgressStatus = 'done' | 'in-progress' | 'pending';
export type IMoveInDateStatus = 'valid' | 'invalid-is-before' | 'invalid-is-after';
export type IMinimalQueryResult<TData> = { isLoading: boolean; error: Error | undefined; data: TData | undefined };

export interface IBookingFlowStateGetters {
  locationId: ComputedRef<number | undefined>;
  sessionDataLoading: ComputedRef<boolean>;
  unitTypeCategory: ComputedRef<IUnitTypeCategory | undefined>;
  unitType: ComputedRef<IUnitType | undefined>;
  unitAvailable: ComputedRef<boolean | undefined>;
  moveInDate: ComputedRef<string | undefined>;
  moveInDateStatus: ComputedRef<undefined | IMoveInDateStatus>;
  bookingPlan: ComputedRef<IBookingPlan | undefined>;
  insurance: ComputedRef<IInsurance | undefined>;
  customerData: ComputedRef<Partial<ICustomerData> | undefined>;
  fullName: ComputedRef<string | undefined>;
  countryId: ComputedRef<string | undefined>;
  discountCode: ComputedRef<IBookingFlowStateModel['discountCode']>;

  // Helpers
  /**
   * The best router location depending on the state data
   */
  currentSuitableRouteTarget: ComputedRef<RouteLocationNamedRaw>;
  stepUnitSelectionDone: ComputedRef<boolean>;
  stepMoveInDateDone: ComputedRef<boolean>;
  stepBookingPlanDone: ComputedRef<boolean>;
  stepNeedsInsurance: ComputedRef<boolean>;
  stepInsuranceDone: ComputedRef<boolean>;
  stepOptionsDone: ComputedRef<boolean>;
  stepOptionsCurrentlyPossibleSections: ComputedRef<(IBookingOptionSection | null)[]>;
  stepCustomerDataStatus: ComputedRef<ICustomerFormProgressStatus>;
  stepCustomerDataDone: ComputedRef<boolean>;

  selectedProducts: ComputedRef<IProduct[]>;
  oneTimeProducts: ComputedRef<IProduct[]>;
  recurringProducts: ComputedRef<IProduct[]>;
  optionalProductsAvailableQuery: ComputedRef<IBookingFlowTemporaryStateModel['optionalProductsAvailable']>;
  unitTypeAvailabilityQuery: ComputedRef<IBookingFlowTemporaryStateModel['unitTypeAvailability']>;
}

function getCopy<T>(item: T | undefined): T | undefined {
  if (!item) {
    return item;
  }

  return cloneDeep(item);
}

function getDefaultState(): IBookingFlowStateModel {
  return {
    sessionDataLoading: false,
    locationId: undefined,
    unitTypeCategory: undefined,
    unitType: undefined,
    moveInDate: undefined,
    unitAvailable: undefined,
    bookingPlan: undefined,
    insurance: undefined,
    discountCode: undefined,
    customerData: undefined,
    selectedProducts: [],
  };
}

export const useBookingFlowStore = defineStore('booking-flow', () => {
  const state = reactive<IBookingFlowStateModel>(getDefaultState());
  const temporaryState = reactive<IBookingFlowTemporaryStateModel>({
    unitTypeAvailability: {
      isLoading: false,
      error: undefined,
      data: undefined,
    },
    optionalProductsAvailable: {
      isLoading: false,
      error: undefined,
      data: undefined,
    },
  });
  const router = useRouter();
  const { save, saveDebounced, get: getSessionData } = useBookingFlowStorePersistence();
  const { minDate, maxDate } = useMoveInDate();
  const { unitTypeCategoryId, setAndReplace } = useCategoryIdQueryParameter();

  async function saveSessionDataToServer() {
    return save(getDataToSave(state));
  }

  /**
   * Returns the new data to save or undefined if nothing has changed and no saving is needed
   * @param state
   */
  function getDataToSave(state: IBookingFlowStateModel): IBookingFlowPredefinedSessionData {
    return {
      locationId: state.locationId || undefined,
      discountCode: state.discountCode || undefined,
      unitType: state.unitType || undefined,
      moveInDate: state.moveInDate || undefined,
      customerData: state.customerData || undefined,
      insurance: state.insurance || undefined,
      unitTypeCategory: state.unitTypeCategory || undefined,
      bookingPlan: state.bookingPlan || undefined,
      selectedProducts: state.selectedProducts || undefined,
    };
  }

  const getters: IBookingFlowStateGetters = {
    locationId: computed(() => state.locationId),
    sessionDataLoading: computed(() => state.sessionDataLoading),
    unitTypeCategory: computed(() => state.unitTypeCategory),
    unitType: computed(() => state.unitType),
    moveInDate: computed(() => state.moveInDate),
    moveInDateStatus: computed<IMoveInDateStatus | undefined>(() => {
      if (!state.moveInDate) {
        return undefined;
      }

      const isBefore = dayjs(state.moveInDate).isBefore(minDate.value, 'day');

      if (isBefore) {
        return 'invalid-is-before';
      }

      const isAfter = dayjs(state.moveInDate).isAfter(maxDate.value, 'day');

      if (isAfter) {
        return 'invalid-is-after';
      }

      return 'valid';
    }),
    unitAvailable: computed(() => state.unitAvailable),
    bookingPlan: computed(() => state.bookingPlan),
    insurance: computed(() => state.insurance),
    customerData: computed(() => state.customerData),
    fullName: computed(() => {
      return (
        [getters.customerData.value?.firstName, getters.customerData.value?.lastName]
          .filter((name) => !!name)
          .join(' ') || undefined
      );
    }),
    countryId: computed(() => state.customerData?.country?.id),
    discountCode: computed(() => state.discountCode || undefined),

    // Options page helpers:
    stepUnitSelectionDone: computed(() => !!state.unitType),
    stepMoveInDateDone: computed(
      () =>
        getters.stepUnitSelectionDone.value &&
        !!state.moveInDate &&
        getters.moveInDateStatus.value === 'valid' &&
        !!getters.unitAvailable.value,
    ),
    stepBookingPlanDone: computed(() => getters.stepMoveInDateDone.value && !!state.bookingPlan),
    stepNeedsInsurance: computed(() => getters.stepBookingPlanDone.value && !!state.bookingPlan?.hasInsurances),
    stepInsuranceDone: computed(
      () =>
        (getters.stepBookingPlanDone.value && getters.stepNeedsInsurance.value && !!state.insurance) ||
        (getters.stepBookingPlanDone.value && !getters.stepNeedsInsurance.value),
    ),
    stepOptionsDone: computed(() => getters.stepInsuranceDone.value),
    stepOptionsCurrentlyPossibleSections: computed<(IBookingOptionSection | null)[]>(() => {
      switch (true) {
        case !getters.stepMoveInDateDone.value:
          return ['moveInDate'];
        case !getters.stepBookingPlanDone.value:
          return ['billingPeriod', 'moveInDate'];
        case getters.stepNeedsInsurance.value && !getters.stepInsuranceDone.value: {
          return ['insurance', 'billingPeriod', 'moveInDate'];
        }
        case getters.optionalProductsAvailableQuery.value.data && !getters.stepNeedsInsurance.value:
          return [null, 'products', 'billingPeriod', 'moveInDate'];
        case getters.optionalProductsAvailableQuery.value.data && getters.stepNeedsInsurance.value:
          return [null, 'products', 'insurance', 'billingPeriod', 'moveInDate'];
        default: {
          const values: (IBookingOptionSection | null)[] = ['billingPeriod', 'moveInDate'];

          if (getters.stepNeedsInsurance.value) {
            values.unshift('insurance');
          }

          if (getters.optionalProductsAvailableQuery.value.data) {
            values.unshift('products');
          }

          values.unshift(null);

          return values;
        }
      }

      throw new Error('Not every case implemented');
    }),
    stepCustomerDataStatus: computed(() => {
      if (state.customerData == null) {
        return 'pending';
      }

      try {
        getCustomerFormDataAndValidate(state.customerData);
        return 'done';
      } catch (e) {
        return 'in-progress';
      }
    }),
    stepCustomerDataDone: computed(
      () => getters.stepOptionsDone.value && getters.stepCustomerDataStatus.value === 'done',
    ),
    currentSuitableRouteTarget: computed<RouteLocationNamedRaw>(() => {
      const unitTypeId = getters.unitType.value?.id;
      const currentRoute = router.currentRoute.value;

      /**
       * stores the page the user should go to according to the booking flow selection. meaning: the latest page possible.
       */
      let desiredPage: string = bookingFlowRouteNames.unitSelection;
      const unitAvailable = getters.unitAvailable.value === true;
      const unitAvailabilityUndetermined = getters.unitAvailable.value === undefined;

      switch (true) {
        case getters.stepCustomerDataDone.value && unitAvailable:
        case getters.stepOptionsDone.value && getters.stepCustomerDataStatus.value === 'in-progress' && unitAvailable:
          desiredPage = bookingFlowRouteNames.customerForm;
          break;
        case getters.stepOptionsDone.value && unitAvailable:
        case getters.stepInsuranceDone.value && unitAvailable:
          desiredPage = bookingFlowRouteNames.bookingBasket;
          break;
        case getters.stepUnitSelectionDone.value && unitAvailable:
        case getters.stepUnitSelectionDone.value && getters.stepMoveInDateDone.value && unitAvailable:
        case getters.stepUnitSelectionDone.value && !getters.stepMoveInDateDone.value && unitAvailabilityUndetermined:
        case getters.stepUnitSelectionDone.value &&
          getters.stepBookingPlanDone.value &&
          getters.stepNeedsInsurance.value &&
          unitAvailable:
          desiredPage = bookingFlowRouteNames.bookingOptions;
          break;
        case !unitAvailable && state.moveInDate != null && currentRoute.name !== bookingFlowRouteNames.unitSelection:
          desiredPage = bookingFlowRouteNames.unitTypeNotAvailable;
          break;
      }

      const currentRouteName = String(currentRoute.name || '');

      // defining the flow of the pages here:
      const pageOrder = bookingFlowPageOder;
      /**
       * The index of the current route in the booking flow
       */
      const currentIndex = pageOrder.indexOf(currentRouteName);
      /**
       * The index of the desired route in the booking flow according to the user selections
       */
      const desiredIndex = pageOrder.indexOf(desiredPage);

      // If the current route is before the last allowed page let the user navigate to this one.
      // Else: go to the desired page by the state, meaning: the user wanted to access a later page in the flow that the selections would allow.
      // --> Move them back to the latest allowed page.
      if (currentIndex > -1 && currentIndex <= desiredIndex) {
        desiredPage = currentRouteName;
      }

      switch (desiredPage) {
        case bookingFlowRouteNames.customerForm:
          return routesFactoryBookingFlow.customerForm(unitTypeId);
        case bookingFlowRouteNames.bookingBasket:
          return routesFactoryBookingFlow.bookingBasket(unitTypeId);
        case bookingFlowRouteNames.unitTypeNotAvailable:
          return routesFactoryBookingFlow.unitTypeNotAvailable(unitTypeId, state.moveInDate as string);
        case bookingFlowRouteNames.bookingOptions: {
          const sections = getters.stepOptionsCurrentlyPossibleSections.value;
          let currentQuerySection: string | null = (router.currentRoute.value.query.section as string) || null;

          if (isString(currentQuerySection) && currentQuerySection.length <= 0) {
            currentQuerySection = null;
          }

          if (sections.includes(currentQuerySection as IBookingOptionSection)) {
            return routesFactoryBookingFlow.bookingOptions(unitTypeId, currentQuerySection as IBookingOptionSection);
          } else {
            return routesFactoryBookingFlow.bookingOptions(unitTypeId, sections?.[0] || null);
          }
        }
        default:
          // If query parameter for the unitTypeCategory is present: use it otherwise fallback to the one of the state
          return routesFactoryBookingFlow.unitTypeSelection(
            unitTypeCategoryId.value || getters.unitTypeCategory.value?.id,
          );
      }
    }),

    selectedProducts: computed(() => state.selectedProducts),
    oneTimeProducts: computed(() => state.selectedProducts.filter((p) => p.frequency === 'one_time')),
    recurringProducts: computed(() => state.selectedProducts.filter((p) => p.frequency === 'recurring')),
    unitTypeAvailabilityQuery: computed(() => temporaryState.unitTypeAvailability),
    optionalProductsAvailableQuery: computed(() => temporaryState.optionalProductsAvailable),
  };

  /**
   * Contains only state mutations. no side effects like API requests!
   */
  const mutations = {
    setLocationId(locationId: Maybe<number>) {
      state.locationId = parseToInteger(locationId, undefined);
    },
    setSessionData(data: IBookingFlowPredefinedSessionData) {
      state.locationId = data.locationId || undefined;
      state.unitTypeCategory = data.unitTypeCategory || undefined;
      state.unitType = data.unitType || undefined;
      state.moveInDate = data.moveInDate || undefined;
      state.bookingPlan = data.bookingPlan || undefined;
      state.insurance = data.insurance || undefined;
      state.customerData = data.customerData || undefined;
      state.discountCode = data.discountCode || undefined;
      state.selectedProducts = data.selectedProducts || [];
    },
    setUnitTypeCategory(unitTypeCategory: IUnitTypeCategory | undefined) {
      state.unitTypeCategory = getCopy(unitTypeCategory);

      if (unitTypeCategory?.id != null && state.unitType && !state.unitType.categoryIds.includes(unitTypeCategory.id)) {
        mutations.setUnitType(undefined);
      }
    },
    setUnitType(unitType: IUnitType | undefined) {
      state.unitType = getCopy(unitType);
      state.moveInDate = undefined;
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.discountCode = undefined;
    },
    setUnitTypeAvailability(available: boolean | undefined) {
      state.unitAvailable = available;
    },
    replaceUnitType(unitType: IUnitType | undefined) {
      state.unitType = getCopy(unitType);
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.discountCode = undefined;
    },
    setMoveInDate(moveInDate: string | undefined) {
      state.moveInDate = moveInDate;
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.discountCode = undefined;
      state.selectedProducts = [];
    },
    setBookingPlan(bookingPlan: IBookingPlan | undefined) {
      state.bookingPlan = bookingPlan;
      state.insurance = undefined;
      state.discountCode = undefined;
      state.selectedProducts = [];
    },
    setInsurance(insurance: IInsurance | undefined) {
      state.insurance = insurance;
      state.discountCode = undefined;
    },
    setDiscountCode(discountCode: string | undefined) {
      state.discountCode = discountCode;
    },
    setCustomerData(customerData: Partial<ICustomerData> | undefined) {
      state.customerData = customerData;
    },
    patchCustomerData(customerData: Partial<ICustomerData> | undefined) {
      state.customerData = {
        ...(state.customerData || {}),
        ...JSON.parse(JSON.stringify(customerData || {})),
      };
    },
    resetFlowToUnitTypeSelection() {
      state.unitType = undefined;
      state.moveInDate = undefined;
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.discountCode = undefined;
      state.customerData = undefined;
      state.unitAvailable = undefined;
      state.selectedProducts = [];
    },

    resetFlowToBookingOptions() {
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.discountCode = undefined;
      state.customerData = undefined;
      state.unitAvailable = undefined;
      state.selectedProducts = [];
    },

    reset() {
      state.unitType = undefined;
      state.moveInDate = undefined;
      state.bookingPlan = undefined;
      state.insurance = undefined;
      state.customerData = undefined;
      state.discountCode = undefined;
      state.customerData = undefined;
      state.unitAvailable = undefined;
      state.selectedProducts = [];
    },

    updateProductQuantity(product: IProduct, quantity: number) {
      const index = state.selectedProducts.findIndex((p) => p.id === product.id && p.frequency === product.frequency);
      if (index !== -1) {
        if (quantity <= 0) {
          state.selectedProducts.splice(index, 1);
        } else {
          state.selectedProducts[index].quantity = quantity;
        }
      } else if (quantity > 0) {
        state.selectedProducts.push({ ...product, quantity });
      }
    },
  };

  const actions = {
    async restoreSession(options: { locationId: number; unitTypeId?: number; unitTypeCategoryId?: number }) {
      state.sessionDataLoading = true;

      // Load session data from storage
      return getSessionData()
        .then((data) => actions.validateSessionDataAndApply(data, options))
        .finally(() => {
          state.sessionDataLoading = false;
        });
    },
    /**
     * @deprecated can be removed
     * @param locationId
     */
    setLocationId(locationId: number | undefined) {
      mutations.setLocationId(locationId);
    },

    async checkAndUpdateUnitTypeCategory(locationId: number, desiredUnitTypeCategory?: number) {
      const unitTypeCategories = (await getUnitTypeCategories([locationId])).data;
      const defaultCategory = unitTypeCategories[0];
      const desired = getById(desiredUnitTypeCategory) || defaultCategory;

      function getById(id?: number) {
        if (id != null) {
          return unitTypeCategories.find((cat) => cat.id === id);
        }

        return undefined;
      }

      if (state.unitType == null) {
        // set to default of backend and the desired one if it is in the BE response
        mutations.setUnitTypeCategory(desired);
        return;
      }

      const unitTypeCategoryIds = state.unitType.categoryIds;
      const firstOrFallback = getById(unitTypeCategoryIds[0]) || defaultCategory;

      if (desiredUnitTypeCategory == null || !state.unitType.categoryIds.includes(desiredUnitTypeCategory)) {
        // set to first unit-type-category
        mutations.setUnitTypeCategory(firstOrFallback);
        return;
      }

      if (state.unitType.categoryIds.includes(desiredUnitTypeCategory)) {
        // unit-type is set and the desired category is included in the unit-type categories
        mutations.setUnitTypeCategory(getById(desiredUnitTypeCategory));
        return;
      }

      mutations.setUnitTypeCategory(desired);
    },

    async updateProductsAvailability() {
      const productsState = temporaryState.optionalProductsAvailable;
      productsState.isLoading = true;
      productsState.error = undefined;
      productsState.data = undefined;

      if (!state.bookingPlan || !state.unitType) {
        return;
      }

      try {
        const data = await getOptionalProducts({
          billingPeriodId: state.bookingPlan.id,
          unitTypeId: state.unitType.id,
        });
        productsState.data = data.data.length > 0;
      } catch (error) {
        productsState.error = error as Error;
      } finally {
        productsState.isLoading = false;
      }
    },

    async getCachedData() {
      return getSessionData();
    },

    async updateUnitTypeCategoryQueryParameter() {
      const stateValue = state.unitTypeCategory?.id;

      if (stateValue != null && unitTypeCategoryId.value !== stateValue) {
        return setAndReplace(stateValue);
      }
    },

    /**
     * Validates the current session data and applies it if possible
     * @param data
     * @param options
     */
    async validateSessionDataAndApply(
      data: IBookingFlowPredefinedSessionData,
      options: {
        locationId: number;
        unitTypeId?: number;
        unitTypeCategoryId?: number;
      },
    ) {
      // setting the data from the storage to the state. But before that validate it
      if (data.locationId !== options.locationId || options.unitTypeId == null) {
        // location id has changed. discard stored data.
        // if no unit-type-id was passed it was missing from the route params. Hence, reset the flow.
        mutations.reset();
        mutations.setLocationId(options.locationId);

        if (options.unitTypeId != null) {
          try {
            const unitType = await getUnitType(options.unitTypeId);
            mutations.setUnitType(unitType);
          } catch (error) {
            console.warn('Error while loading unit type', error);
          }
        }

        await actions.checkAndUpdateUnitTypeCategory(options.locationId, options.unitTypeCategoryId);
        return saveSessionDataToServer();
      }

      const locationId: number | undefined = options.locationId;

      if (data.unitType?.id !== options.unitTypeId) {
        mutations.setSessionData(data);
        // if the given unit type id is not present in the storage reload the unit type id.
        const unitType = await getUnitType(options.unitTypeId);
        mutations.setUnitType(unitType);

        await actions.checkAndUpdateUnitTypeCategory(
          locationId,
          options.unitTypeCategoryId ?? data.unitTypeCategory?.id,
        );
      } else {
        // otherwise set the data
        mutations.setSessionData(data);
        await actions.checkAndUpdateUnitTypeCategory(
          locationId,
          options.unitTypeCategoryId ?? data.unitTypeCategory?.id,
        );

        // If the move in date is in the past reset the flow to the booking options
        const isMoveInDateInThePast = state.moveInDate != null && dayjs(state.moveInDate).isBefore(dayjs(), 'day');

        if (state.unitType == null) {
          // Must never happen, but better safe than sorry
          return this.resetFlowToUnitTypeSelection();
        } else if (state.moveInDate == null || isMoveInDateInThePast) {
          mutations.setUnitType(state.unitType);
        } else if (state.bookingPlan == null) {
          mutations.setMoveInDate(state.moveInDate);
        } else if (getters.stepNeedsInsurance.value && state.insurance == null) {
          mutations.setBookingPlan(state.bookingPlan);
        }
      }

      // When stored data is applied check if the unit is still available if selected
      await actions.updateUnitAvailability();
      await actions.updateProductsAvailability();
      return saveSessionDataToServer();
    },

    async setUnitTypeCategory(unitTypeCategory: IUnitTypeCategory | undefined) {
      if (state.unitTypeCategory?.id === unitTypeCategory?.id) {
        return;
      }
      mutations.setUnitTypeCategory(unitTypeCategory);
      return saveSessionDataToServer();
    },

    async setUnitType(unitType: IUnitType | undefined) {
      if (state.unitType?.id === unitType?.id) {
        return;
      }

      mutations.setUnitType(unitType);
      await actions.updateProductsAvailability();
      return saveSessionDataToServer();
    },

    async replaceUnitType(unitType: IUnitType | undefined) {
      if (state.unitType?.id === unitType?.id) {
        return;
      }

      mutations.replaceUnitType(unitType);
      mutations.setUnitTypeAvailability(unitType?.available);
      await actions.updateProductsAvailability();
      return saveSessionDataToServer();
    },

    async setMoveInDate(moveInDate: string | Date | undefined) {
      if (moveInDate && !state.unitType) {
        return;
      }

      const formattedMoveInDate = moveInDate ? formatISODate(moveInDate) : undefined;

      if (formattedMoveInDate === state.moveInDate) {
        return;
      }

      mutations.setMoveInDate(formattedMoveInDate);
      await actions.updateUnitAvailability();
      return saveSessionDataToServer();
    },

    async updateUnitAvailability() {
      temporaryState.unitTypeAvailability.error = undefined;
      temporaryState.unitTypeAvailability.isLoading = false;
      temporaryState.unitTypeAvailability.data = undefined;

      if (state.moveInDate == null || state.unitType == null || state.unitTypeCategory == null) {
        mutations.setUnitTypeAvailability(undefined);
      } else {
        try {
          temporaryState.unitTypeAvailability.isLoading = true;
          const response = await getAvailableUnitTypes(state.unitType.id, state.unitTypeCategory.id, state.moveInDate);
          mutations.setUnitTypeAvailability(response.data.available);
          temporaryState.unitTypeAvailability.data = response.data;
        } catch (error) {
          mutations.setUnitTypeAvailability(undefined);
          temporaryState.unitTypeAvailability.error = error as Error;
        } finally {
          temporaryState.unitTypeAvailability.isLoading = false;
        }
      }

      await nextTick();
    },

    async setBookingPlan(bookingPlan: IBookingPlan | undefined) {
      if (bookingPlan && (!state.moveInDate || state.bookingPlan?.id === bookingPlan?.id)) {
        return;
      }
      mutations.setBookingPlan(bookingPlan);
      await actions.updateProductsAvailability();
      return saveSessionDataToServer();
    },

    async setInsurance(insurance: IInsurance | undefined) {
      if (insurance && (!state.bookingPlan || state.bookingPlan.hasInsurances === false)) {
        return;
      }

      mutations.setInsurance(insurance);
      return saveSessionDataToServer();
    },

    async setDiscountCode(code: string | undefined) {
      if (code && !getters.stepOptionsDone.value) {
        return;
      }

      mutations.setDiscountCode(code);
      return saveSessionDataToServer();
    },

    async setCustomerData(customerData: Partial<ICustomerData> | undefined) {
      mutations.setCustomerData(customerData ? { ...customerData } : undefined);
      return saveDebounced(state);
    },

    async patchCustomerData(customerData: Partial<ICustomerData> | undefined) {
      mutations.patchCustomerData(customerData ? { ...customerData } : undefined);
      return saveDebounced(state);
    },

    async updateProductQuantity(product: IProduct, quantity: number) {
      mutations.updateProductQuantity(product, quantity);
      return saveSessionDataToServer();
    },

    async redirectBasedOnStateData() {
      if (getters.currentSuitableRouteTarget.value) {
        /*
         * The language parameter will be copied to the target url because they should not matter. Adding is easier than
         * removing. At the end the urls should contain the same not-important query parameters to allow comparing.
         * For instance: The language property should never matter, but query params like the section on the
         * options page do matter.
         */
        const lang = router.currentRoute.value?.query?.lang;
        const currentFullPath = router.currentRoute.value.fullPath;

        const target = {
          ...getters.currentSuitableRouteTarget.value,
          query: {
            ...getters.currentSuitableRouteTarget.value.query,
            lang,
          },
        };

        const nextFullPath = router.resolve(target)?.fullPath;

        if (nextFullPath !== currentFullPath) {
          return router.push(getters.currentSuitableRouteTarget.value);
        }
      }

      return;
    },

    /**
     * Resets the state but not the sessionId, the locationId and the chosen unitTypeCategory
     */
    async resetFlowToUnitTypeSelection() {
      if (
        state.unitType == null &&
        state.moveInDate == null &&
        state.bookingPlan == null &&
        state.insurance == null &&
        state.discountCode == null &&
        state.customerData == null &&
        state.unitAvailable == null
      ) {
        return;
      }
      mutations.resetFlowToUnitTypeSelection();
      return saveSessionDataToServer();
    },

    /**
     * Resets the state but not the sessionId, the locationId and the chosen unitTypeCategory
     */
    async resetFlowToBookingOptions() {
      if (
        state.unitType == null &&
        state.moveInDate == null &&
        state.bookingPlan == null &&
        state.insurance == null &&
        state.discountCode == null &&
        state.customerData == null &&
        state.unitAvailable == null
      ) {
        return;
      }
      mutations.resetFlowToBookingOptions();
      return saveSessionDataToServer();
    },

    async reset() {
      mutations.reset();
      return saveSessionDataToServer();
    },
  };

  return {
    ...state,
    ...actions,
    ...getters,
    mutations,
    // exported extra to allow test cases to spy on the functions
    actions,
  };
});

export type IUseBookingFlowStoreReturnType = ReturnType<typeof useBookingFlowStore>;
