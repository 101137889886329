import 'dayjs/locale/de';
import 'dayjs/locale/sv';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/lv';
import 'dayjs/locale/ru';
import 'dayjs/locale/nl';
import 'dayjs/locale/fi';

import { createI18n, type I18nOptions } from 'vue-i18n';

import { getBrowserLanguage, I18nLocaleStorage } from '@/utils/i18n-utils';

export const languages = ['en', 'de', 'sv', 'es', 'fr', 'nb', 'pl', 'lv', 'ru', 'nl', 'fi'] as const;
const languagesAsString = languages.map(String);
export type ILanguage = (typeof languages)[number];

export const languageMap: Record<ILanguage, { locale: string }> = {
  en: {
    locale: 'en-GB',
  },
  de: {
    locale: 'de-AT',
  },
  sv: {
    locale: 'sv-SE',
  },
  es: {
    locale: 'es-ES',
  },
  fr: {
    locale: 'fr-FR',
  },
  nb: {
    locale: 'nb-NO',
  },
  pl: {
    locale: 'pl-PL',
  },
  lv: {
    locale: 'lv-LV',
  },
  ru: {
    locale: 'ru-RU',
  },
  nl: {
    locale: 'nl-NL',
  },
  fi: {
    locale: 'fi-FI',
  },
};
export const defaultLanguage = 'en';
export const i18nLocaleStorage = new I18nLocaleStorage(getBrowserLanguage(defaultLanguage), languagesAsString);

export const i18nConfig: I18nOptions = {
  legacy: false,
  globalInjection: true,
  locale: i18nLocaleStorage.get(),
  fallbackLocale: defaultLanguage,
  messages: {},
};

export function getLocaleWithCountry(lang: string): string {
  if (lang === 'de') {
    return 'de-DE';
  }
  if (lang in languageMap) {
    return languageMap[lang as ILanguage]?.locale;
  }
  // If not in languageMap, return the original locale without country code
  return lang;
}

export const i18n = createI18n(i18nConfig);
