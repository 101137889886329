import { computed, type ComputedRef } from 'vue';
import { type NavigationFailure, useRouter } from 'vue-router';

import { parseToInteger } from '@/utils/format-utils.ts';

export interface IUseCategoryIdQueryParameterReturnType {
  unitTypeCategoryId: ComputedRef<number | undefined>;
  setAndReplace(value: number | undefined): Promise<NavigationFailure | void | undefined>;
  setAndNavigate(value: number | undefined): Promise<NavigationFailure | void | undefined>;
}

export function useCategoryIdQueryParameter(): IUseCategoryIdQueryParameterReturnType {
  const router = useRouter();
  const unitTypeCategoryId = computed(() =>
    parseToInteger(router.currentRoute.value.query?.unitTypeCategoryId, undefined),
  );

  return {
    unitTypeCategoryId,
    async setAndReplace(value: number | undefined) {
      if (unitTypeCategoryId.value === value) {
        return;
      }

      return router.replace({
        query: {
          ...router.currentRoute.value.query,
          unitTypeCategoryId: value,
        },
      });
    },
    async setAndNavigate(value: number | undefined) {
      if (unitTypeCategoryId.value === value) {
        return;
      }

      return router.push({
        query: {
          ...router.currentRoute.value.query,
          unitTypeCategoryId: value,
        },
      });
    },
  };
}
