import type { InfiniteData } from '@tanstack/query-core';

import type { IPaginatedResponse, IPagination, IPaginationPayload, IResponse } from '@/types/api';

export function getMinPageSize(pageSize?: number | null): number {
  return Math.max(pageSize || 1, 1);
}

/**
 * true, it there is a next page available
 * @param paginationResponse
 */
export function hasNextPage(paginationResponse?: IPagination | null) {
  return paginationResponse && paginationResponse.page < paginationResponse.lastPage;
}

/**
 * Returns the payload for the next page if available.
 * Will return `undefined` if there is no next page.
 * @param paginationResponse
 */
export function getNextPagePayload(paginationResponse?: IPagination | null): IPaginationPayload | undefined {
  if (paginationResponse && hasNextPage(paginationResponse)) {
    return {
      page: Math.min(paginationResponse.page + 1, paginationResponse.lastPage),
      pageSize: getMinPageSize(paginationResponse.pageSize),
    };
  }

  return undefined;
}

/**
 * true, it there is a previous page available
 * @param paginationResponse
 */
export function hasPreviousPage(paginationResponse?: IPagination | null) {
  return paginationResponse && paginationResponse.page > 1;
}

/**
 * Returns the payload for the previous page if available.
 * Will return `undefined` if there is no previous page.
 * @param paginationResponse
 */
export function getPreviousPagePayload(paginationResponse?: IPagination | null): IPaginationPayload | undefined {
  if (paginationResponse && hasPreviousPage(paginationResponse)) {
    return {
      page: Math.max(paginationResponse.page - 1, 1),
      pageSize: getMinPageSize(paginationResponse.pageSize),
    };
  }

  return undefined;
}

export function extractItemsFromInfiniteResult<T>(data?: InfiniteData<IResponse<T[]>> | null): undefined | T[] {
  if (!data) {
    return undefined;
  }

  return data.pages?.reduce((all, cur) => [...all, ...cur.data], [] as T[]);
}

export function isLastPageOfInfiniteLoading<T>(data?: InfiniteData<IPaginatedResponse<T[]>> | null): boolean {
  if (!data?.pages || data?.pages.length <= 0) {
    return false;
  }

  const pagination = data.pages[data.pages.length - 1].pagination;

  return pagination.lastPage <= pagination.page;
}
